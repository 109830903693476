import { useMemo, useState } from 'react'
import type { CanvasSizeId, ConfiguratorValueV1, LightMaterialId } from '../types'

export function useConfiguratorHandler(initial: ConfiguratorValueV1) {
	const [value, setValue] = useState(initial)

	const actions = useMemo(() => {
		return {
			setValue,
			setSize: (canvasSize: CanvasSizeId) => setValue((old) => ({ ...old, canvasSize })),
			setLightMaterial: (lightMaterial: LightMaterialId) =>
				setValue((old) => ({ ...old, lightMaterial })),
			setFontFamily: (font: string) => setValue((old) => ({ ...old, font })),
			reset: () => setValue(() => initial),
			setInputValue: (inputId: string, value: string) => {
				return setValue((old) => {
					if (old.inputValues[inputId] === value) {
						return old
					}

					return { ...old, inputValues: { ...old.inputValues, [inputId]: value } }
				})
			},
		}
	}, [initial])

	return [value, actions] as const
}

export type ConfiguratorHandler = ReturnType<typeof useConfiguratorHandler>
