import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import { Configurator } from '../../neon/configurator/Configurator'
import { useConfiguratorHandler } from '../../neon/configurator/useConfiguratorHandler'
import {
	ConfiguratorInfoContext,
	toConfiguratorInfoContext,
	toInputs,
	toPreset,
	toPresetValue,
} from '../../neon/contember/transformers'
import type { ConfiguratorPreset } from '../../neon/types'
import { IfHydrated } from '../../packages/@necktip/next/hydratation'
import { Container } from '../Container'

export const configurator = function ConfiguratorBlock({
	reference,
}: ReferenceRendererProps<ContentBlockReference>) {
	const { presetValue } = reference

	if (!presetValue) {
		throw new Error('Missing presetValue')
	}

	const { preset } = presetValue

	if (!preset) {
		throw new Error('Missing preset')
	}

	const readyPresetValue = React.useMemo(() => toPresetValue(presetValue), [presetValue])

	const handler = useConfiguratorHandler(readyPresetValue)

	const inputs = React.useMemo(() => {
		return toInputs(presetValue)
	}, [presetValue])

	const context = React.useMemo(() => toConfiguratorInfoContext(preset), [preset])

	const configuratorPreset: ConfiguratorPreset = React.useMemo(() => {
		return {
			...toPreset(preset),
			inputs,
		}
	}, [inputs, preset])

	return (
		<IfHydrated
			yes={() => (
				<section className={clsx('section')}>
					<Container>
						<h2 className="sectionTitle">{reference.primaryText}</h2>
						<ConfiguratorInfoContext.Provider value={context}>
							{presetValue && presetValue.preset && context && (
								<Configurator handler={handler} preset={configuratorPreset} />
							)}
						</ConfiguratorInfoContext.Provider>
					</Container>
				</section>
			)}
		/>
	)
}
