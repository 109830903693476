import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/sideBySide.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'
import { Media } from '../Media'

export const sideBySide = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				<div
					className={clsx(
						styles.inner,
						reference.sideBySideView && styles[reference.sideBySideView]
					)}>
					<div className={styles.content}>
						<div className={styles.icon}>
							{reference.icon && <ContemberImage image={reference.icon} />}
						</div>
						<h2 className={styles.headline}>{reference.primaryText}</h2>
						<article className={styles.description}>{children}</article>
						{reference.link && <Link isBtn link={reference.link} nonExternal />}
					</div>
					<div className={clsx(styles.image, reference.imageView && styles[reference.imageView])}>
						{reference.image && (
							<ContemberImage image={reference.image} objectFit="cover" layout="fill" />
						)}
						{reference.media && <Media media={reference.media} />}
					</div>
				</div>
			</Container>
		</section>
	)
}
