import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/SignPost.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'
import { Media } from '../Media'

export const signPost = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				<div className={styles.headline}>
					{reference.primaryText && <h2 className={styles.headline}>{reference.primaryText}</h2>}
					<div className={styles.logos}>
						{reference.logoList?.items.map((item) => (
							<div className={styles.logo} key={item.id}>
								{item.logo && <ContemberImage image={item.logo} layout="responsive" />}
							</div>
						))}
					</div>
				</div>
				<div className={styles.inner}>
					{reference.tileList?.items.map((item) => (
						<div className={styles.single} key={item.id}>
							<div className={styles.image}>{item.media && <Media media={item.media} cover />}</div>
							{item.link && (
								<Link className="viewLight" isBtn nonExternal spanWrap link={item.link} />
							)}
						</div>
					))}
				</div>
			</Container>
		</section>
	)
}
